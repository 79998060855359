<script setup lang="ts">
const { user } = useUserSession()

const { modules, moduleId } = useModules()
</script>

<template>
  <UDashboardPage>
    <UDashboardPanel grow>
      <UDashboardNavbar title="Inicio">
        <template #right>
          <!-- <AppUsernotificationsButtonSlideover /> -->
          <AppIssuesButtonSlideover />
          <!-- <ButtonApps /> -->
        </template>
      </UDashboardNavbar>

      <UDashboardPanelContent class="p-6">
        <!-- <div class="mb-6">
          <UAlert
            :avatar="{ src: user?.image, alt: user?.name }"
            :title="`¡Bienvenido ${user?.name}!`"
            description="Desde aquí puedes acceder a los módulos a los cuales tengas acceso."
            variant="subtle"
            color="primary"
          />
        </div>

        <UDashboardSection
          title="Módulos"
          description="Los módulos de la aplicación a los que tienes acceso."
        /> -->

        <UPageGrid v-if="modules.length" class="w-full">
          <UPageCard
            v-for="(mod, i) in modules"
            :key="`module-${mod.id}-${i}`"
            v-bind="{
              ...mod,
              title: mod.name,
              to: mod.disabled ? undefined : `/${mod.id}`,
            }"
            :class="[
              mod.disabled ? 'border-none opacity-75 pointer-events-none' : '',
              ['black'].includes(mod.color) ? `text-${mod.color}` : `text-${mod.color}-500`,
            ]"
            :ui="{ icon: { base: ['black'].includes(mod.color) ? `text-${mod.color}` : `text-${mod.color}-500` } }"
          >
            <template #description>
              <span class="line-clamp-2">{{ mod.description }}</span>
            </template>
          </UPageCard>
        </UPageGrid>

        <div
          v-else
          class="flex flex-col items-center justify-center h-full text-center p-6 bg-gray-50"
        >
          <UIcon name="i-mdi-apps" class="text-gray-400 text-6xl mb-6" />
          <h1 class="text-xl font-semibold text-gray-800 mb-4">
            No tienes acceso a ningún módulo de Netzo
          </h1>
          <p class="text-gray-600">
            Contacta al administrador para que te otorgue acceso.
          </p>
        </div>
      </UDashboardPanelContent>
    </UDashboardPanel>
  </UDashboardPage>
</template>
